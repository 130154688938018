import { Globals } from 'utilities/Globals';
import { HttpService } from '../HttpService';
import { CustomCrudService } from '../common/custom-crud-service';
export class NewsService extends CustomCrudService {
    constructor() {
        super([Globals.apiUrl, 'news'].join('/'), HttpService.instance);
    }
}
NewsService.instance = new NewsService();
export const newsService = NewsService.instance;
