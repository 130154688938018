import React from 'react';
import { render } from 'react-dom';
import regeneratorRuntime from 'regenerator-runtime';
import 'state/ModelEnhancer';
import { styleController } from 'style/styleController';
import { Globals } from 'utilities/Globals';
import { Main } from './Main';
// reference regen runtime so it won't get tree-shaken
regeneratorRuntime;
const $root = document.getElementById('root');
const $style = document.getElementById(styleController.tagId);
function createApp() {
    render(React.createElement(Main, null), $root);
}
// check if styles were created before this piece of code has been reached (somehow, it has happened)
if ($style.innerHTML.length > 0) {
    createApp();
}
else {
    /**
     * Create a MutationObserver that observes our main app style tag.
     * The app should not render before the styles are done rendering.
     *
     * The app does not like to be exposed while naked 🙈
     */
    new MutationObserver(function (mutations, observer) {
        for (let mutation of mutations) {
            // if child list has changed -- style rules have been added to the style element
            if (mutation.type === 'childList') {
                // stop observing
                observer.disconnect();
                // create app
                createApp();
            }
        }
    }).observe($style, {
        attributes: true,
        childList: true,
        characterData: true,
    });
}
console.groupCollapsed('Globals');
console.log('API_URL', Globals.apiUrl);
console.log('date input supported', Globals.dateInputSupported);
console.log('development', Globals.isDevelopment);
console.log('production', Globals.isProduction);
console.log('version', Globals.appVersion);
console.groupEnd();
