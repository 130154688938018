import React, { Fragment } from 'react';
/**
 * Creates a list of components given data and a rendering template.
 */
export function List(props) {
    const { items, template } = props;
    const nodes = [];
    if (!items)
        return React.createElement(React.Fragment, null);
    for (let [key, value] of items.entries()) {
        const node = template(value, key, items);
        nodes.push(node);
    }
    return React.createElement(Fragment, null, nodes);
}
