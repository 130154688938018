import { styleController } from 'style/styleController';
import { Colors } from 'style/Colors';
import { Mixins } from 'style/Mixins';
import { Fonts } from 'style/Fonts';
import { Spacing } from 'style/Spacing';
import { Dimensions } from 'style/Dimensions';
export const classNames = styleController.createStyleMap({
    container: styleController.createClass({
        $debugName: 'exova-platform',
    }),
    tooltipContainer: styleController.createClass({
        pointerEvents: 'none',
        position: 'fixed',
        zIndex: 999,
        width: 0,
        height: 0,
    }),
    tooltip: styleController.createClass(Mixins.boxShadow(), {
        position: 'absolute',
        backgroundColor: Colors.violetBlue.toString(),
        color: 'white',
        padding: 16,
        width: '95vw',
        maxWidth: 350,
        pointerEvents: 'none',
        $nest: {
            '*': { pointerEvents: 'none' },
            '&>*:first-child': {
                marginTop: 0,
            },
            '&>*:last-child': {
                marginBottom: 0,
            },
        },
    }),
    tooltipUp: styleController.createClass({
        bottom: 0,
    }),
    tooltipDown: styleController.createClass({
        top: 0,
    }),
    tooltipLeft: styleController.createClass({
        right: 0,
    }),
    tooltipRight: styleController.createClass({
        left: 0,
    }),
    mobileWarning: styleController.createClass(Mixins.flexContainerCenterContent(), Mixins.column(), Mixins.row(1, true), {
        visibility: 'hidden',
        background: Colors.exovaPrimary.toString(),
        color: Colors.white.toString(),
        height: '100vh',
        left: 0,
        position: 'fixed',
        textAlign: 'center',
        lineHeight: '1.25rem',
        top: 0,
        width: '100vw',
        zIndex: 999,
    }),
    notificationContainer: styleController.createClass(Mixins.flexContainerCenterContent('column'), {
        padding: styleController.join([Spacing.vertical, 0]),
        position: 'fixed',
        bottom: 0,
        width: '100vw',
        zIndex: 40,
    }),
    notification: styleController.createClass(Mixins.row(0.5), {
        cursor: 'pointer',
        position: 'relative',
        minWidth: Dimensions.minUIWidth * 0.8,
        padding: styleController.join([Spacing.vertical, Spacing.horizontal]),
        backgroundColor: Colors.leermapGrey.toString(),
        color: 'white',
        font: `1.4rem ${Fonts.Dosis.regular}`,
        transition: 'opacity 1s ease-in-out',
        opacity: 0,
        maxWidth: Dimensions.minUIWidth - Spacing.horizontal * 2,
    }),
    notificationShown: styleController.createClass(Mixins.flexContainerCenterContent(), {
        opacity: 0.8,
    }),
    notificationHidden: styleController.createClass(Mixins.flexContainerCenterContent(), {
        opacity: 0,
    }),
    notificationButton: styleController.createClass(Mixins.flexContainerCenterContent(), Mixins.column(2), {
        color: Colors.white.toString(),
        height: '100%',
        border: 'none',
        backgroundColor: Colors.leermapGrey.toString(),
        font: `1.4rem ${Fonts.Dosis.regular}`,
        textDecoration: 'none',
        position: 'absolute',
        right: 0,
    }),
});
