import { ModelEnhancer } from 'state/ModelEnhancer';
import { Asset } from '../assets/Asset';
import { User } from '../users/User';
/**
 * News data and related utilities.
 */
export class NewsItem extends ModelEnhancer {
    createStruct(data) {
        return {
            author: data.author && User,
            assets: data.assets && Asset,
        };
    }
}
