import { Popup } from 'components/elements/Popup';
import React, { useMemo, useState } from 'react';
import { router } from 'routing/Router';
import { truncate } from 'utilities/truncate';
import { classNames } from './NewsPopup.style';
export const NewsPopup = (props) => {
    const [showNewsPopup, setNewsPopup] = useState(true);
    const title = useMemo(() => truncate(props.news.title, 40), [
        props.news.title,
    ]);
    const description = useMemo(() => truncate(props.news.body, 250), [
        props.news.body,
    ]);
    return (React.createElement(Popup, { title: 'Nieuw nieuwsbericht!', toggled: showNewsPopup, confirmText: "Lees meer", onCloseClick: () => {
            setNewsPopup(false);
        }, onConfirmClick: () => {
            router.navigate((map) => map.news['/'].details(props.news.id));
            setNewsPopup(false);
        } },
        React.createElement("div", { className: classNames.newsContainer },
            React.createElement("a", { target: "_blank", rel: "noreferrer", className: classNames.newsItem },
                React.createElement("div", { className: classNames.newsItemTitle }, title),
                React.createElement("div", { className: classNames.newsItemText }, description)))));
};
