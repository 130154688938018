import { styleController } from 'style/styleController';
import { Colors } from 'style/Colors';
import { Fonts } from 'style/Fonts';
import { Spacing } from 'style/Spacing';
export const classNames = styleController.createStyleMap({
    container: styleController.createClass(),
    button: styleController.createClass({
        cursor: 'pointer',
        height: 55,
        minWidth: 207,
        fontFamily: Fonts.Dosis.regular.fontFamily,
        fontSize: 20,
        padding: `${Spacing.vertical}px ${Spacing.horizontal}px`,
        /** reset */
        outline: 'none',
        border: 'none',
        borderRadius: 0,
        $nest: {
            '&:disabled': {
                background: Colors.grey.toString(),
                color: Colors.white.toString(),
                cursor: 'auto',
            },
        },
    }),
    /** button varieties */
    variantCareful: styleController.createClass({
        background: Colors.mathRed.toString(),
        color: Colors.white.toString(),
        $debugName: 'button-careful',
    }),
    variantNeutral: styleController.createClass({
        background: Colors.grey.toString(),
        color: Colors.text.toString(),
        $debugName: 'button-neutral',
    }),
    variantPrimary: styleController.createClass({
        background: Colors.exovaPrimary.toString(),
        color: Colors.white.toString(),
        $debugName: 'button-primary',
    }),
});
