import { Globals } from '../utilities/Globals';
/**
 * Turns data object into a class instance.
 * The instance will inherit all the properties from the given data.
 * Also, you can provide a struct to enhance nested properties.
 * @param data the data which the class will inherit
 *
 * @example ../documentation/ModelEnhancer.example.md
 */
export class ModelEnhancer {
    constructor(__data) {
        this.__data = __data;
        const data = this.parseModel(__data);
        const struct = this.createStruct(data);
        // get each key of the model, and set it as an instance property
        for (let modelKey of Object.keys(data)) {
            const value = data[modelKey];
            // if a struct is provided, check if the key should be constructed
            // else just set instance property
            if (struct) {
                const structKeys = Object.keys(struct);
                const constructor = struct[modelKey];
                // if the struct includes the key, try to instantiate it's value
                if (structKeys.includes(modelKey)) {
                    // check primitive value
                    if (new RegExp('string|number|boolean').test(typeof value)) {
                        this.defineProperty(modelKey, this.construct(constructor, value));
                        continue;
                    }
                    // check array or object
                    if (typeof value === 'object') {
                        this.defineProperty(modelKey, Array.isArray(value)
                            ? value.map((item) => this.construct(constructor, item))
                            : this.construct(constructor, value), constructor);
                        continue;
                    }
                }
                else {
                    // if the struct does not include the key, assign the value to instance property
                    this.defineProperty(modelKey, value);
                }
            }
            else
                this.defineProperty(modelKey, value);
        }
        this.checkForBoundMethods();
    }
    checkForBoundMethods() {
        setTimeout(() => {
            for (let key of Object.keys(this)) {
                const value = this[key];
                const name = this.constructor.name.toLowerCase();
                if (typeof value === 'function') {
                    console.warn([
                        `Anonymous function expression @ ${this.constructor.name}<${this.__data.id}>.`,
                        "😔 This will hog the browser's memory and that is not very nice.",
                        `💡 Try to define \`${key}\` as class method, and bind using \`${name}.${key}.call(${name})\` instead.`,
                    ].join('\n'));
                }
            }
        });
    }
    /**
     * Parse the model data; applies the following processes:
     * - Turns ISO dates into actual Date objects.
     * @param data model data object
     */
    parseModel(data) {
        const keys = Object.keys(data);
        for (let k of keys) {
            const key = k;
            const value = data[key];
            if (typeof value === 'string') {
                data[key] = Globals.isoDateRegExp.test(value)
                    ? new Date(value)
                    : value;
            }
        }
        return data;
    }
    /**
     * Define a property on this class instance
     * @param key the property name
     * @param value the property value
     * @param constructor (optional) the constructor to use to set the property after creation
     */
    defineProperty(key, value, constructor) {
        const self = this;
        // if a constructor is given, we need to define a setter on the property,
        // so when the property changes, a new constructor is instantiated
        if (constructor) {
            // create a 'private' key for the struct for writing and make it not configurable and not enumerable
            // so it is hidden as much as possible
            // also it's prefixed by "__" so we know it should not be touched/used :)
            const structKey = `__${key.toString()}`;
            Object.defineProperty(this, structKey, {
                value,
                enumerable: false,
                configurable: false,
                writable: true,
            });
            // define getter and setter properties
            Object.defineProperty(this, key, {
                // return the struct
                get() {
                    return this[structKey];
                },
                // set uses the ModelEnhancer construct method to create a property instance
                set(to) {
                    this[structKey] = self.construct(constructor, to);
                },
                configurable: false,
                enumerable: true,
            });
        }
        else {
            // there is no constructor given, so the value is simply defined on this instance.
            Object.defineProperty(this, key, {
                enumerable: true,
                value,
                writable: true,
            });
        }
    }
    /**
     * Construct a new nested instance (or value if no constructor is provided)
     * @param constructor
     * @param params
     */
    construct(constructor, params) {
        return constructor
            ? params instanceof ModelEnhancer
                ? params
                : new constructor(params)
            : params;
    }
}
