import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faCaretDown, faCaretLeft, faCaretRight, faCaretUp, faCheck, faEllipsisH, faPaperclip, faPaperPlane, faPlus, faPrint, faSort, faSortDown, faSortUp, faTimes, faTrashAlt, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
library.add(faArrowLeft, faArrowLeft, faCaretDown, faCaretLeft, faCaretRight, faCaretUp, faCheck, faEllipsisH, faPaperclip, faPaperPlane, faPlus, faPrint, faSort, faSortDown, faSortUp, faTimes, faTrashAlt);
const ExovaIcon = ({ icon }) => {
    const name = icon.split('-').slice(1).join('-');
    const src = `assets/images/icons/${name}.svg`;
    const use = `${src}#icon`;
    return (React.createElement("svg", { viewBox: "0 0 20 20", width: "1em", height: "1em" },
        React.createElement("use", { xlinkHref: use, width: "100%", height: "100%" })));
};
/**
 * Represents an inline icon element.
 *
 * ** You have to import every icon you use from fontawesome! **
 */
export const Icon = (props) => {
    const custom = typeof props.icon === 'string' ? props.icon.startsWith('ex-') : false;
    if (custom) {
        const icon = props.icon;
        return React.createElement(ExovaIcon, { icon: icon });
    }
    return React.createElement(FontAwesomeIcon, Object.assign({}, props));
};
