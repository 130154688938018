import Color from 'color';
export const Colors = {
    code26Blue: Color('#049EFF'),
    code26Brown: Color('#594A42'),
    darkBlue: Color('#5B2BE0'),
    exovaPrimary: Color('#7C51F4'),
    exovaPrimaryLight: Color('#8F6AF5'),
    exhibitionYellow: Color('#F8A619'),
    exWeGreen: Color('#67BA84'),
    flatBlue: Color('#0F88D4'),
    generalBlue: Color('#2ca9e0'),
    grey: Color('#D6D6D6'),
    kurkenOrange: Color('#a6560c'),
    leermapGrey: Color('#4B4D4D'),
    lightBlue: Color('#1089FF'),
    lightGrey: Color('#EDEDED'),
    mathGreen: Color('#66D78E'),
    mathRed: Color('#FC5959'),
    offWhite: Color('#F6F6F6'),
    orange: Color('#E48330'),
    text: Color('#4B4D4D'),
    violetBlue: Color('#643ADC'),
    white: Color('#FEFEFE'),
    whiteSmoke: Color('#F4F4F4'),
    seaGreen: Color('#6cb5b3'),
};
export const GoalsColors = {
    '1': Color('#EE003F'),
    '2': Color('#E2A14B'),
    '3': Color('#40984C'),
    '4': Color('#CC0334'),
    '5': Color('#F62E36'),
    '6': Color('#00B9D0'),
    '7': Color('#F8BB45'),
    '8': Color('#94173F'),
    '9': Color('#E95A3B'),
    '10': Color('#D1025C'),
    '11': Color('#FF973D'),
    '12': Color('#C3893D'),
    '13': Color('#387F4B'),
    '14': Color('#028EBB'),
    '15': Color('#50BA54'),
    '16': Color('#01618a'),
    '17': Color('#163f58'),
};
Object.freeze(Colors);
