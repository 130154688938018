import React, { Suspense } from 'react';
import { classNames } from './main.style';
import { Switch, Route } from 'react-router-dom';
import { ErrorBoundary } from 'components/elements/ErrorBoundary';
import { Router, router } from 'routing/Router';
import { Globals } from 'utilities/Globals';
import { List } from 'components/elements/List';
import { Icon } from 'components/elements/Icon';
import { cleanJoin } from 'utilities/cleanJoin';
import { authenticationService } from 'state/authentication/authenticationService';
import { classes } from 'typestyle';
import { NewsPopupCenter } from 'components/templates/NewsPopupCenter';
/**
 * Application root component. Provides a general error boundary and routing.
 */
export class Main extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            notifications: [],
            showNotification: false,
            showTooltip: false,
        };
        this.notificationTime = 8000;
        this.notificationFadeTime = 1000;
        this.showNotification = (message) => {
            if (this.notificationTimeout) {
                clearTimeout(this.notificationTimeout);
            }
            this.notificationTimeout = window.setTimeout(this.resetNotifications, this.notificationTime);
            if (this.notificationFadeTimeout) {
                clearTimeout(this.notificationFadeTimeout);
            }
            this.notificationFadeTimeout = window.setTimeout(() => {
                this.setState({ showNotification: false });
            }, this.notificationTime - this.notificationFadeTime);
            this.setState((state) => ({
                notifications: [...state.notifications, message],
                showNotification: true,
            }));
        };
        // Show tooltip and fill it with content
        this.showTooltip = (content, mouseCoords) => {
            this.setState({
                showTooltip: typeof content === 'object' && (content === null || content === void 0 ? void 0 : content.props.children.length) > 0,
                tooltipContent: content,
                tooltipX: mouseCoords === null || mouseCoords === void 0 ? void 0 : mouseCoords.x,
                tooltipY: mouseCoords === null || mouseCoords === void 0 ? void 0 : mouseCoords.y,
            });
        };
        this.closeTooltip = () => {
            this.setState({ showTooltip: false });
        };
        this.createTooltip = (content, index) => {
            return (React.createElement("div", { key: `tooltip-${index}` }, this.state.showTooltip && (React.createElement("div", { className: classNames.tooltipContainer, style: {
                    top: this.state.tooltipY,
                    left: this.state.tooltipX,
                } },
                React.createElement("div", { className: classes(classNames.tooltip, (this.state.tooltipY || 0) > window.innerHeight / 2
                        ? classNames.tooltipUp
                        : classNames.tooltipDown, (this.state.tooltipX || 0) > window.innerWidth / 2
                        ? classNames.tooltipLeft
                        : classNames.tooltipRight) }, content)))));
        };
        this.createNotification = (notification, index) => {
            return (React.createElement("div", { className: cleanJoin(classNames.notification, this.state.showNotification
                    ? classNames.notificationShown
                    : classNames.notificationHidden), style: {
                    transitionDuration: this.notificationFadeTime + 'ms',
                }, key: index, onClick: this.handleNotificationClick, "data-index": index },
                notification,
                React.createElement("div", { className: classNames.notificationButton },
                    React.createElement(Icon, { icon: "times" }))));
        };
        this.handleNotificationClick = (event) => {
            const $target = event.currentTarget;
            const index = $target.dataset.index;
            if (!index)
                return;
            this.handleRemove(Number(index));
        };
        this.handleRemove = (index) => {
            this.setState((state) => {
                const newState = state.notifications.slice();
                newState.splice(index, 1);
                return {
                    notifications: newState,
                };
            });
        };
        this.resetNotifications = () => {
            this.setState({
                notifications: [],
                showNotification: false,
            });
        };
    }
    render() {
        const routes = router.getRouteList().filter((route) => !!route.component);
        return (React.createElement("div", { className: classNames.container },
            React.createElement(ErrorBoundary, null,
                React.createElement(Suspense, { fallback: null },
                    React.createElement(Router.Element, { history: router.history },
                        React.createElement(Switch, null, routes.map((route) => (React.createElement(Route, { component: route.component, key: route.path, path: route.path, exact: route.path !== '*' })))))),
                !!this.state.notifications.length && (React.createElement("div", { className: classNames.notificationContainer },
                    React.createElement(List, { items: this.state.notifications, template: this.createNotification }))),
                React.createElement(List, { items: [this.state.tooltipContent], template: this.createTooltip })),
            React.createElement(NewsPopupCenter, null)));
    }
    componentDidMount() {
        Main.instance = this;
        authenticationService.validate();
        const $root = document.getElementById('root');
        $root.setAttribute('data-app-version', Globals.appVersion);
    }
}
