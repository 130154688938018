var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { cachedResult } from 'utilities/cachedResult';
import { styleController } from './styleController';
import { Spacing } from './Spacing';
import { Dimensions } from './Dimensions';
/**
 * Style mixins
 */
export class Mixins {
    /**
     * Creates a css class for a `display: flex` container
     * @param flexDirection flex direction CSS property
     */
    static flexContainer(flexDirection = 'row') {
        return styleController.createClass({ display: 'flex', flexDirection });
    }
    /**
     * Creates a css class for a `display: flex` container, width horizontally and vertically centered content
     * @param flexDirection flex direction CSS property
     */
    static flexContainerCenterContent(flexDirection) {
        return styleController.createClass(Mixins.flexContainer(flexDirection), {
            justifyContent: 'center',
            alignItems: 'center',
        });
    }
    /**
     * Creates a css class name for a flex child
     * @param size the flex size
     */
    static flex(size = 1) {
        return styleController.createClass({
            flex: size,
        });
    }
    /**
     * Creates a css class name for row spacing
     *
     * @param spacingFactor multiplies the default spacing
     * @param usePadding use padding instead of margin
     */
    static row(spacingFactor = 1, usePadding = false) {
        const key = `${usePadding ? 'p' : 'm'}-${spacingFactor}`;
        const space = Spacing.vertical * spacingFactor;
        return styleController.createClass({
            $debugName: `row-${key}`,
            [usePadding ? 'paddingTop' : 'marginTop']: `${space}px`,
            [usePadding ? 'paddingBottom' : 'marginBottom']: `${space}px`,
        });
    }
    /**
     * Creates a css class name for column spacing
     *
     * @param spacingFactor multiplies the default spacing
     */
    static column(spacingFactor = 1, useMargin = false) {
        const key = `${useMargin ? 'm' : 'p'}-${spacingFactor}`;
        const space = Spacing.horizontal * spacingFactor;
        return styleController.createClass({
            $debugName: `col-${key}`,
            [useMargin ? 'marginLeft' : 'paddingLeft']: `${space}px`,
            [useMargin ? 'marginRight' : 'paddingRight']: `${space}px`,
        });
    }
    /**
     * Creates a css class name for a box shadow
     */
    static boxShadow(xOffset = 0, yOffset = 10, blur = 40, spread = -15, color = 'rgba(0,0,0,0.6)') {
        const boxShadow = `${xOffset}px ${yOffset}px ${blur}px ${spread}px ${color}`;
        return styleController.createClass({
            $debugName: 'shadow',
            boxShadow,
            MozBoxShadow: boxShadow,
            '-webkit-box-shadow': boxShadow,
        });
    }
    static hardGradient(color1, color2, direction = '90deg', raw = false) {
        const gradient = `linear-gradient(${direction}, ${color1} 0%, ${color1} 50%, ${color2} 50%, ${color2} 100%)`;
        return raw
            ? gradient
            : styleController.createClass({
                backgroundImage: gradient,
            });
    }
}
Mixins.widthConstraint = styleController.createClass({
    minWidth: Dimensions.minUIWidth,
    maxWidth: Dimensions.maxUIWidth,
});
/** Limit text lines to one single line, and add ellipsis for overflowing text. */
Mixins.singleLineEllipsis = styleController.createClass({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
});
__decorate([
    cachedResult
], Mixins, "flexContainer", null);
__decorate([
    cachedResult
], Mixins, "flexContainerCenterContent", null);
__decorate([
    cachedResult
], Mixins, "flex", null);
__decorate([
    cachedResult
], Mixins, "row", null);
__decorate([
    cachedResult
], Mixins, "column", null);
__decorate([
    cachedResult
], Mixins, "boxShadow", null);
__decorate([
    cachedResult
], Mixins, "hardGradient", null);
