import { useMemo } from 'react';
/**
 * Transform model data using a given Constructor class
 * @param data
 * @param Constructor
 */
export function useAsModel(data, Constructor) {
    const model = useMemo(() => {
        if (!data)
            return null;
        if (!Constructor)
            return data;
        if (Array.isArray(data)) {
            return data.map((item) => new Constructor(item));
        }
        return new Constructor(data);
    }, [data, Constructor]);
    return model;
}
