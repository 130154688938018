var _a;
import { HttpService as _HttpService } from 'nest-utilities-client';
import { router } from 'routing/Router';
function isPublicPath(path) {
    if (path === router.getRoutePath((map) => map.login))
        return true;
    if (path.includes(router.getRoutePath((map) => map.forgotPassword)))
        return true;
    return false;
}
/**
 * Basic HTTP service that implements authorization headers and authentication utilities.
 */
export class HttpService extends _HttpService {
    static logout() {
        // Remove session storage data
        localStorage.removeItem(HttpService.sessionKey);
        // Remove nest-utilities-client cached data
        Object.keys(localStorage).forEach((key) => {
            if (key.startsWith('nest-utilities-client')) {
                localStorage.removeItem(key);
            }
        });
    }
    getHeaders(_url, _init) {
        const session = JSON.parse(localStorage.getItem(HttpService.sessionKey) || 'null');
        return {
            Authorization: (session === null || session === void 0 ? void 0 : session.token) || 'unauthenticated',
        };
    }
    onRequestError(error) {
        if (error.status === 401) {
            HttpService.logout();
            if (!isPublicPath(location.pathname)) {
                // location.href = '/';
                // console.warn('redirect');
            }
        }
        throw error;
    }
}
HttpService.instance = new HttpService();
HttpService.sessionStorageKey = 'authentication-key';
HttpService.sessionKey = (_a = localStorage.getItem(HttpService.sessionStorageKey)) !== null && _a !== void 0 ? _a : 'unset';
