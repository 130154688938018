import { Role, } from '@hulanbv/exova-platform-packages';
import { License } from 'state/licenses/License';
import { ModelEnhancer } from 'state/ModelEnhancer';
import { School } from 'state/schools/School';
import { cleanJoin } from 'utilities/cleanJoin';
/**
 * User data and related utilities.
 */
export class User extends ModelEnhancer {
    createStruct(data) {
        return {
            attendingSchool: (data.attendingSchool && School),
            licenses: (data.licenses && License),
        };
    }
    get fullName() {
        return cleanJoin(this.firstName, this.middleName, this.lastName);
    }
    get roleName() {
        return User.roleLabels[this.role];
    }
}
User.roleLabels = {
    [Role.BACK_OFFICE]: 'Backoffice',
    [Role.COACH]: 'Leerkracht',
    [Role.CONTENT_MANAGER]: 'Contentbeheerder',
    [Role.FOUNDATION_MANAGER]: 'Schoolbeheerder',
    [Role.STUDENT]: 'Leerling',
    [Role.SUPERUSER]: 'Beheerder',
};
