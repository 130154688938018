/**
 * Transform a React HTML attribute to a regular HTML attribute.
 *
 * React uses custom attributes because of reserved words in Javascript.
 * https://reactjs.org/docs/dom-elements.html
 * @param attribute the html attribute string
 */
function transformReactHtmlAttribute(attribute) {
    /**
     * Handle cases of custom React HTML attributes.
     * React asserts camel case on event attributes. HTML asserts lower case.
     * Also there are some other exceptions...
     */
    switch (attribute) {
        case 'autoComplete':
            return attribute.toLowerCase();
    }
    return attribute.indexOf('on') === 0 ? attribute.toLowerCase() : attribute;
}
/**
 * Extracts (valid) html attributes from react props. This is useful when a react component inherits html attributes as props.
 *
 * @example
 * type Props = AnchorHTMLAttributes & { show: boolean }
 * const MyCustomAnchor: FC<Props> = (props) => (
 *     props.show ? <a {...extractHtmlAttributes(props)} /> : null
 * )
 * // implementation
 * <MyCustomAnchor href="www.hulan.nl" show={true} />
 *
 * @param tag html element tag
 * @param props react component props
 * @param remove attributes to remove
 */
export function extractHtmlAttributes(tag, props, remove = []) {
    /**
     * Reference the function body
     */
    const self = extractHtmlAttributes;
    // create cache keys
    const cacheProperty = '_cache';
    const combinationCacheKey = '_combinations';
    /**
     * Reference the cache in self body, or create if not existing,
     */
    if (!self.hasOwnProperty(cacheProperty)) {
        Object.defineProperty(self, cacheProperty, { value: {} });
    }
    // reference function cache
    const cache = self[cacheProperty];
    /**
     * Reference combinations key in cache, or create if not existing.
     */
    const cachedCombinations = combinationCacheKey in cache
        ? cache[combinationCacheKey]
        : (cache[combinationCacheKey] = []);
    /**
     * Use the tag parameter to get the html element reference from cache,
     * or create a new element if not existing.
     */
    const $element = cache[tag] || (cache[tag] = document.createElement(tag));
    /**
     * Return a new object with the non-valid keys filtered.
     */
    return Object.keys(props).reduce((accumulator, key) => {
        // skip property if match is found
        if (remove === null || remove === void 0 ? void 0 : remove.includes(key))
            return accumulator;
        /**
         * Create a tag + key combination (like "div:class")
         */
        const combination = [tag, key].join(':');
        /**
         * If this combination was already processed as valid,
         * add to accumulator and return.
         */
        if (cachedCombinations.includes(combination)) {
            return Object.assign(Object.assign({}, accumulator), { [key]: props[key] });
        }
        // convert the react html attribute to regular html attribute
        const attribute = transformReactHtmlAttribute(key);
        // If the html attribute exists on the element, add it to cached combinations,
        // add it to the accumulator and return.
        const isDataSet = attribute.startsWith('data-');
        const valid = attribute === 'defaultValue' ||
            (attribute !== 'children' && attribute in $element) ||
            isDataSet;
        if (valid) {
            if (!isDataSet)
                cachedCombinations.push(combination);
            return Object.assign(Object.assign({}, accumulator), { [key]: props[key] });
        }
        return accumulator;
    }, {});
}
// type ImageProps = { customProp: string } & ImgHTMLAttributes<HTMLAnchorElement>;
// const props: ImageProps = { customProp: 'a', src: 'images/wasbeer.jpg' };
// const attrs = extractHtmlAttributes('img', props, ['className']);
// attrs.src; // OK
// attrs.className; // Property 'className' does not exist on type ...
