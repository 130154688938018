import React from 'react';
import { classNames } from './button.style';
import { extractHtmlAttributes } from 'utilities/extractHtmlAttributes';
import { useJoined } from 'utilities/hooks/useJoined';
const appearanceClassNames = {
    [0 /* Careful */]: classNames.variantCareful,
    [1 /* Neutral */]: classNames.variantNeutral,
    [2 /* Primary */]: classNames.variantPrimary,
};
/**
 * Represents a clickable button, used to submit forms or anywhere in a document for accessible, standard button functionality.
 *
 * Has multiple appearances, which can be set using the `appearance` prop.
 * Defaults to `ButtonVariant.Primary`.
 *
 * @example <Button
              appearance={ButtonVariant.Neutral}
              onClick={() => alert("Click!")}
            >
              Click me
            </Button>
 */
export const Button = (props) => {
    const { children, appearance } = props;
    const buttonHtmlAttributes = extractHtmlAttributes('button', props);
    const variant = appearance !== null && appearance !== void 0 ? appearance : 2 /* Primary */;
    const variantClassName = appearanceClassNames[variant];
    const buttonClassName = useJoined(classNames.button, variantClassName, buttonHtmlAttributes.className, [variantClassName]);
    return (React.createElement("button", Object.assign({}, buttonHtmlAttributes, { type: buttonHtmlAttributes.type || 'button', className: buttonClassName }), children));
};
