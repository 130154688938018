import React, { Fragment } from 'react';
import { classNames } from './errorPage.style';
export const ErrorPage = (props) => {
    return (React.createElement("div", { className: classNames.container, style: { backgroundImage: `url(${props.imageSource})` } },
        React.createElement("div", { className: classNames.content },
            React.createElement("div", { className: classNames.title }, props.title),
            React.createElement("div", { className: classNames.column },
                React.createElement("div", { className: classNames.subtitle }, props.subtitle),
                React.createElement(Fragment, null, props.children)))));
};
