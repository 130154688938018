class Font {
    constructor(fontFamily, src) {
        this.fontFamily = fontFamily;
        this.src = src;
    }
    toString() {
        return this.fontFamily;
    }
}
export const rootSize = 14;
export const Fonts = {
    Dosis: {
        bold: new Font('Dosis Bold', 'url(assets/fonts/dosis-bold.ttf)'),
        book: new Font('Dosis Book', 'url(assets/fonts/dosis-book.ttf)'),
        extraBold: new Font('Dosis Extra Bold', 'url(assets/fonts/dosis-extra-bold.ttf)'),
        extraLight: new Font('Dosis Extra Light', 'url(assets/fonts/dosis-extra-light.ttf)'),
        light: new Font('Dosis Light', 'url(assets/fonts/dosis-light.ttf)'),
        medium: new Font('Dosis Medium', 'url(assets/fonts/dosis-medium.ttf)'),
        regular: new Font('Dosis Regular', 'url(assets/fonts/dosis-regular.ttf)'),
        semiBold: new Font('Dosis Semi Bold', 'url(assets/fonts/dosis-semi-bold.ttf)'),
    },
    Miso: {
        light: new Font('Miso Light', 'url(assets/fonts/miso-light.otf)'),
        regular: new Font('Miso Regular', 'url(assets/fonts/miso.otf)'),
        bold: new Font('Miso Bold', 'url(assets/fonts/miso-bold.otf)'),
    },
};
Object.freeze(Fonts);
