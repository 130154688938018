import { styleController } from 'style/styleController';
import { Spacing } from 'style/Spacing';
import { Mixins } from 'style/Mixins';
import { Colors } from 'style/Colors';
import { Fonts } from 'style/Fonts';
export const classNames = styleController.createStyleMap({
    container: styleController.createClass({
        $debugName: 'boundary',
        color: Colors.leermapGrey.toString(),
        backgroundPosition: 'bottom 5vh right 5vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '40% auto',
        height: '100vh',
        borderTop: `${Spacing.vertical}px solid ${Colors.exovaPrimary}`,
    }),
    title: styleController.createClass({
        font: `5rem ${Fonts.Dosis.regular}`,
    }),
    subtitle: styleController.createClass(Mixins.row(3), {
        width: 550,
        fontSize: '1.5625rem',
        lineHeight: '1.25em',
    }),
    content: styleController.createClass(Mixins.row(4, true), Mixins.widthConstraint, Mixins.column(2), {
        margin: '0 auto',
    }),
    column: styleController.createClass(Mixins.column(3)),
    summary: styleController.createClass(Mixins.row(2), {
        textDecoration: 'underline',
        fontSize: '1.5625rem',
        color: Colors.grey.toString(),
    }),
    details: styleController.createClass(Mixins.column(), {
        whiteSpace: 'pre-wrap',
    }),
});
