import { styleController } from 'style/styleController';
import { Colors } from 'style/Colors';
import { Mixins } from 'style/Mixins';
export const classNames = styleController.createStyleMap({
    summary: styleController.createClass(Mixins.row(2), {
        textDecoration: 'underline',
        fontSize: '1.5625rem',
        color: Colors.grey.toString(),
    }),
    details: styleController.createClass(Mixins.column(), {
        whiteSpace: 'pre-wrap',
    }),
});
