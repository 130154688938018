import { createBrowserHistory } from 'history';
import { RouteTree } from './RouteTree';
import { camelToKebab } from 'utilities/camelToKebab';
import { routeMap } from './routeMap';
import { Router as ReactRouter } from 'react-router-dom';
/**
 * Router controller that provides and builds routes and manages navigation.
 *
 * Uses browser history by https://www.npmjs.com/package/history
 */
export class Router {
    constructor() {
        /**
         * Browser history object
         */
        this.history = createBrowserHistory();
        /**
         * Map of route information.
         */
        this.routes = routeMap;
        /**
         * Route tree structure.
         */
        this.routeTree = [];
        /**
         * Used in `createRouteGetters`, `getRoutePath` and `routes` to define a hidden root path for route descriptions.
         * @example
         * const routeMap = {
         *    animals: {
         *      // has hidden root `/animals`
         *      raccoon: {
         *        // has hidden root `/animals/raccoon`
         *      }
         *    }
         * };
         */
        this.routeRootKey = '__root';
        /**
         * Navigate to a page based on given route properties or callback.
         *
         * @example
         * navigate(router.routes.animals['/'].raccoon)
         * // or
         * navigate(routes => routes.animals)
         */
        this.navigate = (to, searchParams) => {
            if (typeof to === 'string') {
                this.history.push(this.createPath(to, searchParams));
            }
            else {
                const target = to instanceof Function ? to(this.routes) : to;
                this.history.push(this.getRoutePath(target, searchParams));
            }
        };
        /**
         * Returns the absolute path for the given route properties.
         *
         * @example
         * getRoutePath(router.routes.animals['/'].raccoon)
         * // or
         * getRoutePath(routes => routes.animals)
         */
        this.getRoutePath = (from, searchParams) => {
            if (typeof from === 'string')
                return this.createPath(from, searchParams);
            // return the absolute path of a route using the hidden root key
            // * the root key is added in `this.createRouteGetter`
            const path = from instanceof Function
                ? from(this.routes)[this.routeRootKey]
                : from[this.routeRootKey];
            return this.createPath(path, searchParams);
        };
        /**
         * Get a flat array of the all the routes in the route tree structure.
         * Practical when rendering routes to an array of react components.
         */
        this.getRouteList = (routeTree = this.routeTree) => {
            // recursively add the route and it's children to the accumulative array.
            const children = routeTree.reduce((result, current) => {
                if (!current.children)
                    return result;
                return result.concat(this.getRouteList(current.children));
            }, []);
            // sort the list to make sure the wildcard route '*' is always last
            // because the routes are put in a Switch (react-router component)
            const list = routeTree
                .concat(children)
                .sort((a, b) => (a.path === '*' ? 1 : b.path === '*' ? -1 : 0));
            return list;
        };
        this.routes = this.init(routeMap);
    }
    createPath(name, searchParams = {}) {
        return [
            name,
            Object.entries(searchParams)
                .map(([key, value]) => (value ? `${key}=${encodeURI(value)}` : key))
                .join('&'),
        ]
            .filter(Boolean)
            .join('?');
    }
    /**
     * Build the route tree and create absolute route paths.
     */
    init(map) {
        this.routeTree = Object.keys(map).map((key) => this.createRouteTree(key, map[key]));
        return this.createRouteGetters(map);
    }
    /**
     * Create a route tree from a structure of `RouteProperties`.
     * @param key object key value used as route path
     * @param from route properties
     */
    createRouteTree(key, from) {
        // convert the route object key to kebab case (backOffice => back-office)
        // this looks better in the address bar
        const properties = typeof from === 'function' ? from() : from;
        const { urlParams, pathname } = properties;
        const path = urlParams
            ? urlParams.join('/')
            : pathname
                ? pathname
                : camelToKebab(key);
        // get the nested routes
        const nested = properties['/'] || {};
        // recursively create route trees from children
        return new RouteTree(path, properties.name || '', properties.component || null, ...Object.keys(nested).map((key) => this.createRouteTree(key, properties['/'][key])));
    }
    /**
     * Create route path "getters". Writes absolute paths to the `routes` object so that embedded keys resolve to an absolute path.
     * @param from `RouteType`
     * @param prefix the route path prefix, used to recursively build absolute paths
     */
    createRouteGetters(from, prefix = '') {
        const result = {};
        const paramsPlaceholder = ':params';
        // console.groupCollapsed(prefix || 'create route getters');
        // return empty result if `_from` route is undefined
        if (!from) {
            // console.groupEnd();
            return result;
        }
        // recursively create absolute paths for each route object
        // absolute paths are created through providing the current route as a prefix parameter for nested routes
        Object.keys(from).forEach((key) => {
            var _a;
            // console.groupCollapsed(key);
            const isFunctionRoute = from[key] instanceof Function;
            const route = isFunctionRoute
                ? from[key]()
                : from[key];
            // create the route path
            const path = [
                prefix,
                isFunctionRoute
                    ? paramsPlaceholder
                    : (_a = route.pathname) !== null && _a !== void 0 ? _a : camelToKebab(key),
            ].join('/');
            // assign the path to the hidden root key and add nested routes under the '/' key
            if (isFunctionRoute) {
                Object.defineProperty(result, key, {
                    value: (...params) => ({
                        [this.routeRootKey]: [prefix, params.join('/')].join('/'),
                        '/': this.createRouteGetters(route['/'], path.replace(paramsPlaceholder, params.join('/'))),
                    }),
                });
            }
            else {
                Object.defineProperty(result, key, {
                    value: {
                        [this.routeRootKey]: path,
                        '/': this.createRouteGetters(route['/'], path),
                    },
                });
            }
            // console.groupEnd();
        });
        // console.groupEnd();
        return result;
    }
}
/**
 * react-router `Router` component. This is only here because it has the same name as this class and might cause conflicts when implementing both.
 */
Router.Element = ReactRouter;
Router.instance = new Router();
export const router = Router.instance;
