export var ExovaIconName;
(function (ExovaIconName) {
    ExovaIconName["Afbeelding"] = "ex-afbeelding";
    ExovaIconName["AfbeeldingExova"] = "ex-afbeelding-exova";
    ExovaIconName["Antwoordblad"] = "ex-antwoordblad";
    ExovaIconName["BetaalbareEnergie"] = "ex-betaalbare-en-duurzame-energie";
    ExovaIconName["Cedicu"] = "ex-cedicu";
    ExovaIconName["Check"] = "ex-check";
    ExovaIconName["CheckCircle"] = "ex-check-circle";
    ExovaIconName["CheckExova"] = "ex-check-exova";
    ExovaIconName["DoeActiviteit"] = "ex-doe-activiteit";
    ExovaIconName["DoeActiviteitExova"] = "ex-doe-activiteit-exova";
    ExovaIconName["DuurzameGemeenschappen"] = "ex-duurzame-gemeenschappen";
    ExovaIconName["EerlijkWerk"] = "ex-eerlijk-werk";
    ExovaIconName["Eye"] = "ex-eye";
    ExovaIconName["File"] = "ex-file";
    ExovaIconName["Filmpje"] = "ex-filmpje";
    ExovaIconName["FilmpjeExova"] = "ex-filmpje-exova";
    ExovaIconName["GeenArmoede"] = "ex-geen-armoede";
    ExovaIconName["GeenHonger"] = "ex-geen-honger";
    ExovaIconName["GelijkeKansen"] = "ex-gelijke-kansen";
    ExovaIconName["GenderEquality"] = "ex-gender-equality";
    ExovaIconName["Gereedschappen"] = "ex-gereedschappen";
    ExovaIconName["Gezondheidszorg"] = "ex-gezondheidszorg";
    ExovaIconName["GroeimapTak"] = "ex-groeimap-branch";
    ExovaIconName["GroepsActiviteitExova"] = "ex-groeps-activiteit-exova";
    ExovaIconName["Helicopter"] = "ex-helicopter";
    ExovaIconName["IndustrieInnovatie"] = "ex-industrie-innovatie";
    ExovaIconName["InfoCircle"] = "ex-info-circle";
    ExovaIconName["Instructie"] = "ex-instructie";
    ExovaIconName["InstructieExtern"] = "ex-instructie-ext";
    ExovaIconName["KlimaatveranderingStoppen"] = "ex-klimaatverandering-stoppen";
    ExovaIconName["KwaliteitsOnderwijs"] = "ex-kwaliteits-onderwijs";
    ExovaIconName["LevenInWater"] = "ex-leven-in-water";
    ExovaIconName["LevenOpLand"] = "ex-leven-op-land";
    ExovaIconName["Liedje"] = "ex-liedje";
    ExovaIconName["Montessori"] = "ex-montessori";
    ExovaIconName["Msv"] = "ex-msv";
    ExovaIconName["Paperclip"] = "ex-paperclip";
    ExovaIconName["Partnerschap"] = "ex-partnerschap";
    ExovaIconName["PlayCircle"] = "ex-play-circle";
    ExovaIconName["Potlood"] = "ex-potlood";
    ExovaIconName["Prullenbak"] = "ex-prullenbak";
    ExovaIconName["Question"] = "ex-question";
    ExovaIconName["Quiz"] = "ex-quiz";
    ExovaIconName["School"] = "ex-school";
    ExovaIconName["SchoonWater"] = "ex-schoon-water";
    ExovaIconName["Search"] = "ex-search";
    ExovaIconName["SearchLarge"] = "ex-search-large";
    ExovaIconName["SoundSample"] = "ex-soundsample";
    ExovaIconName["Spelletje"] = "ex-spelletje";
    ExovaIconName["SpelletjeExova"] = "ex-spelletje-exova";
    ExovaIconName["Strategiekaart"] = "ex-strategiekaart";
    ExovaIconName["Tag"] = "ex-tag";
    ExovaIconName["Uitlegkaart"] = "ex-uitlegkaart";
    ExovaIconName["UitlegkaartExtern"] = "ex-uitlegkaart-ext";
    ExovaIconName["VerantwoordeConsumptie"] = "ex-verantwoorde-consumptie";
    ExovaIconName["Verhaal"] = "ex-verhaal";
    ExovaIconName["VerhaalExova"] = "ex-verhaal-exova";
    ExovaIconName["VredeVeiligheid"] = "ex-vrede-veiligheid";
    ExovaIconName["Website"] = "ex-website";
    ExovaIconName["Werkblad"] = "ex-werkblad";
    ExovaIconName["WerkbladExtern"] = "ex-werkblad-ext";
})(ExovaIconName || (ExovaIconName = {}));
