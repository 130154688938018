import React from 'react';
import { classNames } from './errorBoundary.style';
import { Button } from '../Button';
import { router } from 'routing/Router';
import { ErrorPage } from 'components/templates/ErrorPage';
/**
 * Catches JavaScript errors anywhere in it's child component tree, logs those errors, and displays a fallback UI instead of the component tree that crashed.
 *
 * https://reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            error: null,
            errorInfo: null,
            showDetails: false,
        };
        this.subtitle = 'Probeer het opnieuw. Als het probleem zich voor blijft doen, neem dan contact op met de Evoxa helpdesk';
        this.handleDetailClick = () => {
            this.setState((state) => ({ showDetails: !state.showDetails }));
        };
        this.navigateToDashboard = () => {
            this.setState({ error: null, errorInfo: null, showDetails: false });
            router.navigate('/');
        };
    }
    componentDidCatch(error, errorInfo) {
        console.error({ error, errorInfo });
        this.setState({
            error,
            errorInfo: errorInfo,
        });
    }
    render() {
        const { error, errorInfo } = this.state;
        if (error) {
            return (React.createElement(ErrorPage, { title: "Sorry! Er is iets mis gegaan...", subtitle: this.subtitle, imageSource: "assets/images/error.svg" },
                React.createElement(Button, { appearance: 2 /* Primary */, onClick: this.navigateToDashboard }, "Terug naar dashboard"),
                React.createElement("div", { className: classNames.summary, onClick: this.handleDetailClick }, "Meer details"),
                this.state.showDetails && (React.createElement("div", { className: classNames.details },
                    error.toString(),
                    React.createElement("br", null), errorInfo === null || errorInfo === void 0 ? void 0 :
                    errorInfo.componentStack))));
        }
        return this.props.children;
    }
}
