import { useMemo } from 'react';
import { cleanJoin } from 'utilities/cleanJoin';
const defaultAccumulator = [[], void 0];
const reducer = (accumulator, value) => Array.isArray(value)
    ? [accumulator[0], value]
    : [accumulator[0].concat(value), accumulator[1]];
/**
 * Create a concatenated string from an indefinite number of arguments.
 * Anything void, null or false will be filtered out, and the result is memoized.
 *
 * Optimize memoization by passing a dependency array as the last argument.
 *
 * @param argsAndDeps arguments
 */
export function useJoined(...argsAndDeps) {
    const [classNames, dependencies] = argsAndDeps.reduce(reducer, defaultAccumulator);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => cleanJoin(...classNames), dependencies);
}
