const pkg = require('../../package.json');
/**
 * Global app info and data.
 */
export class Globals {
    constructor() {
        if (!Globals.apiUrl)
            throw new Error('Api url not defined');
    }
    static get appVersion() {
        const v = `${pkg.name} v${pkg.version}`;
        return v.charAt(0).toUpperCase() + v.slice(1);
    }
}
Globals.apiUrl = process.env.API_URL;
Globals.isDevelopment = process.env.NODE_ENV === 'development';
Globals.isProduction = process.env.NODE_ENV === 'production';
Globals.isoDateRegExp = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
Globals.urlRegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
Globals.dateInputSupported = (function () {
    const $input = document.createElement('input');
    $input.setAttribute('type', 'date');
    const notADateValue = 'not-a-date';
    $input.setAttribute('value', notADateValue);
    return $input.value !== notADateValue;
})();
Globals.instance = new Globals();
