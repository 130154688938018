import { Colors } from 'style/Colors';
import { Fonts } from 'style/Fonts';
import { Spacing } from 'style/Spacing';
import { styleController } from 'style/styleController';
const boxSpacing = `${Spacing.vertical}px 0px`;
export const classNames = styleController.createStyleMap({
    newsItem: styleController.createClass({
        color: Colors.leermapGrey.toString(),
        padding: boxSpacing,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '10%',
        width: '90%',
    }),
    newsItemText: styleController.createClass({
        marginTop: Spacing.vertical,
        font: `1.2rem ${Fonts.Dosis.medium}`,
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    }),
    newsContainer: styleController.createClass({
        width: '50vw',
    }),
    header: styleController.createClass({
        marginBottom: 20,
    }),
    newsItemTitle: styleController.createClass({
        font: `1.6rem ${Fonts.Dosis.light}`,
        paddingBottom: 10,
        textAlign: 'center',
    }),
    newsItemSubtitle: styleController.createClass({
        font: `1.1rem ${Fonts.Dosis.regular}`,
        backgroundColor: 'rgba(75, 77, 77, 0.1)',
        marginRight: 10,
        padding: 7,
        borderRadius: '32px',
    }),
    newsItemSubtitleContainer: styleController.createClass({
        display: 'flex',
        flexDirection: 'row',
    }),
});
