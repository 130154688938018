import { styleController } from 'style/styleController';
import { Mixins } from 'style/Mixins';
import Color from 'color';
import { Colors } from 'style/Colors';
import { Fonts } from 'style/Fonts';
import { Spacing } from 'style/Spacing';
export const classNames = styleController.createStyleMap({
    container: styleController.createClass(Mixins.flexContainerCenterContent(), {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 999,
    }),
    window: styleController.createClass(Mixins.boxShadow(0, 0, 50, 0, Color('#000').alpha(0.2).toString()), Mixins.flexContainer('column'), {
        width: 'auto',
        height: 'auto',
        maxHeight: '80vh',
        maxWidth: '80vw',
        background: Colors.white.toString(),
        overflow: 'hidden',
    }),
    title: styleController.createClass(Mixins.row(2), {
        font: `2.375rem ${Fonts.Dosis.regular}`,
        textAlign: 'center',
    }),
    content: styleController.createClass(Mixins.flex(), {
        minHeight: 0,
        overflow: 'auto',
    }),
    buttons: styleController.createClass(Mixins.flexContainerCenterContent(), Mixins.column(), Mixins.row(2), {
        $nest: {
            '> *': {
                margin: styleController.join([0, Spacing.horizontal]),
            },
        },
    }),
});
