var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useEffect, useRef } from 'react';
import { useAll, useDelete, useById, useMany, usePatch, usePost, usePut, FetchTiming } from '@hulanbv/nest-utilities-client-state';
import { useAsModel } from './useAsModel';
/**
 * Provides hooks for using & maintaining remote data. Instantiates utilized data if an `itemConstructor` class is defined.
 */
export class Store {
    constructor(service, itemConstructor) {
        this.service = service;
        this.itemConstructor = itemConstructor;
    }
    useCollection(httpOptions, stateOptions) {
        const _a = useAll(this.service, httpOptions, stateOptions), { data } = _a, rest = __rest(_a, ["data"]);
        const models = useAsModel(data, this.itemConstructor);
        return Object.assign({ data: models }, rest);
    }
    useCreator(httpOptions) {
        const _a = usePost(this.service, httpOptions), { data } = _a, rest = __rest(_a, ["data"]);
        const model = useAsModel(data, this.itemConstructor);
        return Object.assign({ data: model }, rest);
    }
    useItem(id, httpOptions, stateOptions) {
        const _a = useById(this.service, id, httpOptions, stateOptions), { data } = _a, rest = __rest(_a, ["data"]);
        const model = useAsModel(data, this.itemConstructor);
        return Object.assign({ data: model }, rest);
    }
    useSet(ids, httpOptions) {
        const _a = useMany(this.service, ids, httpOptions), { data } = _a, rest = __rest(_a, ["data"]);
        const models = useAsModel(data, this.itemConstructor);
        return Object.assign({ data: models }, rest);
    }
    useUpdater(id, httpOptions, method = 'PUT', fetchTiming = FetchTiming.IMMEDIATE) {
        var _a;
        const _immediateFetch = useRef(fetchTiming);
        const { data: patched, fetchState: patchState, response: patchResponse, call: patchCall } = usePatch(this.service, id, httpOptions, {
            fetchTiming: FetchTiming.WHEN_EMPTY
        });
        const { data: put, fetchState: putState, response: putResponse, call: putCall } = usePut(this.service, id, httpOptions, {
            fetchTiming: FetchTiming.WHEN_EMPTY
        });
        const initiated = useRef(false);
        const model = ((_a = patched !== null && patched !== void 0 ? patched : put) !== null && _a !== void 0 ? _a : null);
        const item = useAsModel(model, this.itemConstructor);
        useEffect(() => {
            var _a, _b;
            if (!_immediateFetch.current || !id)
                return;
            const callback = () => (initiated.current = true);
            if (method === 'PATCH')
                (_a = patchCall(null, true)) === null || _a === void 0 ? void 0 : _a.then(callback);
            if (method === 'PUT')
                (_b = putCall(null, true)) === null || _b === void 0 ? void 0 : _b.then(callback);
        }, [patchCall, putCall, method, id]);
        if (method === 'PATCH') {
            return {
                call: patchCall,
                data: item,
                fetchState: patchState,
                response: patchResponse,
                service: this.service
            };
        }
        return {
            call: putCall,
            data: item,
            fetchState: putState,
            response: putResponse,
            service: this.service
        };
    }
    useRemover(id, httpOptions, fetchTiming = FetchTiming.IMMEDIATE) {
        const _a = useDelete(this.service, id, httpOptions, {
            fetchTiming
        }), { data } = _a, rest = __rest(_a, ["data"]);
        const model = useAsModel(data, this.itemConstructor);
        return Object.assign({ data: model }, rest);
    }
    log(...args) {
        console.log(this.constructor.name, ...args);
    }
}
