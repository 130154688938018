import React from 'react';
import { classNames } from './popup.style';
import { Button } from '../Button';
import { Dimensions } from 'style/Dimensions';
/**
 * Presents content in a box that pops over (on top of) other content.
 */
export const Popup = (props) => {
    const { cancelText = 'Sluiten', children, confirmText = 'OK', onCloseClick, onConfirmClick, title, toggled = false, height = Dimensions.popupMediumHeight, width = Dimensions.popupMediumWidth, } = props;
    return toggled ? (React.createElement("div", { className: classNames.container, onClick: onCloseClick },
        React.createElement("div", { className: classNames.window, style: { width: width, height: height }, onClick: (e) => e.stopPropagation() },
            title && React.createElement("div", { className: classNames.title }, title),
            React.createElement("div", { className: classNames.content }, children),
            React.createElement("div", { className: classNames.buttons },
                React.createElement(Button, { appearance: 1 /* Neutral */, onClick: onCloseClick }, cancelText),
                onConfirmClick && (React.createElement(Button, { appearance: 2 /* Primary */, onClick: onConfirmClick }, confirmText)))))) : null;
};
