import { ModelEnhancer } from 'state/ModelEnhancer';
import { LicenseType } from '@hulanbv/exova-platform-packages';
import { User } from 'state/users/User';
import { School } from 'state/schools/School';
export class License extends ModelEnhancer {
    createStruct(data) {
        return {
            user: data.user && User,
            school: data.school && School,
        };
    }
    getTypeLabel() {
        return License.typeLabels[this.type];
    }
}
License.typeLabels = {
    [LicenseType.MATH]: 'Math',
    [LicenseType.CODE_26]: 'Code 26',
    [LicenseType.EXPEDITIE_WERELD]: 'Expeditiewereld',
};
