import React from 'react';
import { withRouter } from 'react-router-dom';
/**
 * Route tree structure given a.o. a path and it's children.
 * Provides breadcrumbs and injects router props to it's component.
 *
 * @example
 * // use injected props in your route component
 * const { route } = this.props;
 * router.breadCrumbs
 */
export class RouteTree {
    constructor(_path, pathName, component, ...children) {
        this._path = _path;
        this.pathName = pathName;
        this.component = component;
        this.injectProperties = (C) => {
            if (!C)
                return null;
            // define withRouter as typed component
            const WithRouter = withRouter(C);
            // create a FC that injects our router props
            const Wrapped = () => (React.createElement(WithRouter, { route: {
                    parent: this.parent,
                    path: this.path,
                    pathName: this.pathName,
                    breadCrumbs: this.getBreadCrumbs(),
                } }));
            return Wrapped;
        };
        // set this instance as parent of the children
        this.children = children.map((child) => {
            child.parent = this;
            return child;
        });
        // inject properties to this component
        this.component = component ? this.injectProperties(component) : null;
    }
    get path() {
        var _a;
        // build an absolute path using the parent's path
        const p = [(_a = this.parent) === null || _a === void 0 ? void 0 : _a.path, this._path].filter(Boolean).join('/');
        // return the path prefixed by '/' unless it's a wildcard path (*)
        return p === '*' ? p : (p[0] === '/' ? '' : '/') + p;
    }
    get parent() {
        return this._parent || null;
    }
    set parent(value) {
        if (!!value && !this._parent)
            this._parent = value;
    }
    getBreadCrumbs(from = this) {
        // build an array of breadcrumbs, providing the path and pathName
        return from
            ? [
                ...this.getBreadCrumbs(from.parent),
                { path: from.path, pathName: from.pathName },
            ]
            : [];
    }
}
