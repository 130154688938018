import { Store } from 'state/Store';
import { NewsItem } from './NewsItem';
import { newsService } from './newsItemService';
export class NewsStore extends Store {
    constructor() {
        super(newsService, NewsItem);
    }
    getNewsReadDate() {
        var _a;
        return new Date(parseInt((_a = localStorage.getItem('lastNewsReadDate')) !== null && _a !== void 0 ? _a : '0'));
    }
    setNewsReadDate(date) {
        if (date > this.getNewsReadDate()) {
            localStorage.setItem('lastNewsReadDate', date.getTime().toString());
        }
    }
}
NewsStore.instance = new NewsStore();
export const newsStore = NewsStore.instance;
