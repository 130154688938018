export const Dimensions = {
    maxUIWidth: 1440,
    minUIWidth: 1024,
    minUIHeight: 768,
    minInputWidth: 264,
    inputHeight: 50,
    navBarHeight: 60,
    popupSmallWidth: 500,
    popupMediumWidth: 900,
    popupSmallHeight: 400,
    popupMediumHeight: 600,
};
Object.freeze(Dimensions);
