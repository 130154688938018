var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CrudService } from 'nest-utilities-client';
class CustomCrudService extends CrudService {
    getMany(ids, httpOptions) {
        const _super = Object.create(null, {
            getMany: { get: () => super.getMany }
        });
        return __awaiter(this, void 0, void 0, function* () {
            // fetch the ids with a maximum of 100 per request
            const models = [];
            let response = null;
            for (let i = 0; i < ids.length; i += 100) {
                if (i >= ids.length) {
                    break;
                }
                const chunk = ids.slice(i, i + 100);
                response = yield _super.getMany.call(this, chunk, httpOptions);
                models.push(...response.data);
            }
            if (!response) {
                throw new Error('No response from getMany');
            }
            response.data = models;
            return response;
        });
    }
}
export { CustomCrudService };
