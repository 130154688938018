import React from 'react';
import { useAll } from '@hulanbv/nest-utilities-client-state';
import { newsService } from 'state/newsItems/newsItemService';
import { newsStore } from 'state/newsItems/newsItemStore';
import { NewsPopup } from '../NewsPopup';
import { NewsItem } from 'state/newsItems/NewsItem';
export const NewsPopupCenter = () => {
    const { data } = useAll(newsService, {
        sort: ['showAsPopup', '-updatedAt'],
        limit: 1,
        match: {
            updatedAt: { $gt: newsStore.getNewsReadDate().toISOString() },
            showAsPopup: true,
        },
        populate: ['author'],
    });
    if (data === null || data === void 0 ? void 0 : data.length) {
        return React.createElement(NewsPopup, { news: new NewsItem(data[0]) });
    }
    else {
        return React.createElement(React.Fragment, null);
    }
};
