import { normalize, setupPage } from 'csstips';
import { createTypeStyle } from 'typestyle';
import { Colors } from './Colors';
import { Globals } from 'utilities/Globals';
import { Fonts, rootSize } from './Fonts';
export class StyleController {
    constructor() {
        /**
         * Create a style element
         * assign an id
         * append the style element
         * instantiate TypeStyle and pass our style element
         */
        /**
         * Html ID attribute for the main style element that is used by the controller
         */
        this.tagId = 'main-app-style';
        /**
         * Font stack
         */
        this.fontStack = [
            Fonts.Dosis.bold,
            Fonts.Dosis.book,
            Fonts.Dosis.extraBold,
            Fonts.Dosis.extraLight,
            Fonts.Dosis.light,
            Fonts.Dosis.medium,
            Fonts.Dosis.regular,
            Fonts.Dosis.semiBold,
            Fonts.Miso.light,
            Fonts.Miso.regular,
            Fonts.Miso.bold,
        ];
        const $style = document.createElement('style');
        $style.id = this.tagId;
        document.head.appendChild($style);
        this.typeStyle = createTypeStyle($style);
        this.fontStack.forEach((ff) => this.typeStyle.fontFace(ff));
        setupPage('#root');
        normalize();
        // set base html styles
        // fontSize is set on the root element; use 'rem' as a unit for font sizes
        this.rule('html', {
            background: Colors.exovaPrimary.toString(),
            fontSize: rootSize + 'px',
        });
        // set base body styles
        this.rule('body', {
            background: Colors.white.toString(),
            color: Colors.text.toString(),
            fontFamily: ['sans-serif', 'Helvetica Neue', 'Dosis Regular'],
            fontSize: '1rem',
            height: 'unset',
            minHeight: '100%',
        });
        this.rule('a', {
            color: Colors.exovaPrimary.toString(),
        });
        // Create a style to fill the style tag (used in DOM Mutation observer, check src/index.ts)
        this.rule(`.Guarantee-${this.tagId}`, {
            $debugName: `Guarantee-${this.tagId}`,
            all: 'unset',
        });
    }
    /**
     * Create a CSS class name from strings and CSS properties.
     *
     * @param from Strings and CSS property objects
     * @returns Combined CSS class names
     */
    createClass(...from) {
        const mediaQueries = {};
        const nestedCssProperties = from
            .filter((value) => typeof value === 'object')
            .map((css) => {
            const values = css;
            if (values.$nest) {
                for (let key of Object.keys(values.$nest)) {
                    if (key.includes('@media')) {
                        mediaQueries[key] = values.$nest[key];
                    }
                }
            }
            if (Globals.isProduction)
                delete values.$debugName;
            return values;
        });
        const classNames = from.filter((value) => typeof value === 'string');
        classNames.push(this.typeStyle.style(...nestedCssProperties, { $nest: mediaQueries }));
        return classNames.join(' ');
    }
    join(values, unit = 'px') {
        return values.map((v) => v + unit).join(' ');
    }
    /**
     * Resticts style map types (keys should only have string values so styles are created once)
     * @param map Map with nested css properties
     */
    createStyleMap(map) {
        return map;
    }
    /**
     * Creates a CSS rule.
     *
     * @param selector CSS selector
     * @param objects CSS properties
     */
    rule(selector, ...objects) {
        return this.typeStyle.cssRule(selector, ...objects);
    }
    /**
     * Creates raw CSS.
     *
     * @param selector CSS selector
     * @param objects CSS properties
     */
    raw(css) {
        return this.typeStyle.cssRaw(css);
    }
    /**
     * Make a css property important
     * @param value css value
     */
    important(value) {
        return `${value} !important`;
    }
}
/**
 * StyleController instance (singleton)
 */
StyleController.instance = new StyleController();
export const styleController = StyleController.instance;
