/**
 * Turns a string from camel case to kebab case
 * @param s string to convert
 *
 * @example
 * camelToKebab("iLikeBread");
 * // output: "i-like-bread"
 */
export function camelToKebab(s) {
    return s.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
}
