import { lazy } from 'react';
// utility to make import statements shorter, for readability
const l = (module) => lazy(() => import(`@screens/${module}`));
const AdminUserManagement = l('AdminUserManagement');
const Activities = l('Activities');
const BackOffice = l('BackOffice');
const Code26 = l('Code26');
const Code26Dashboard = l('Code26Dashboard');
const ExpWeCreateWorld = l('ExpWeCreateWorld');
const DataDetails = l('DataDetails');
const DomainManagement = l('DomainManagement');
const Domains = l('Domains');
const Exhibitions = lazy(() => import('../exhibitions-module/components/screens/exhibitions.screen'));
const ExhibitionDetails = lazy(() => import('../exhibitions-module/components/screens/exhibition-detail.screen'));
const ExhibitionCreate = lazy(() => import('../exhibitions-module/components/screens/exhibition-create.screen'));
const ExhibitionUpdate = lazy(() => import('../exhibitions-module/components/screens/exhibition-update.screen'));
const ExpWeWorldDetails = l('ExpWeWorldDetails');
const ExpWe = l('ExpWe');
const ExpWeDashboard = l('ExpWeDashboard');
const ExpWeDomainDetails = l('ExpWeDomainDetails');
const ExpWeWork = l('ExpWeWork');
const ForgotPassword = l('ForgotPassword');
const Gallery = l('Gallery');
const GalleryItemDetails = l('GalleryItemDetails');
const GroupManagement = l('GroupManagement');
const LearningGoalManagement = l('LearningGoalManagement');
const LearningResourceManagement = l('LearningResourceManagement');
const LibraryItemManagement = l('LibraryItemManagement');
const LicenseDetails = l('LicenseDetails');
const LicenseRequest = l('LicenseRequest');
const LicenseManagement = l('LicenseManagement');
const TotalLicenseManagement = l('TotalLicenseManagement');
const Login = l('Login');
const Math = l('Math');
const MathDashboard = l('MathDashboard');
const Messages = l('Messages');
const ModuleDomainManagement = l('ModuleDomainManagement');
const News = l('News');
const NewsItemDetails = l('NewsItemDetails');
const NewsItemManagement = l('NewsItemManagement');
const NavSearch = l('NavSearch');
const NoAccess = l('NoAccess');
const NotFound = l('NotFound');
const SchoolDetails = l('SchoolDetails');
const StudentOverview = l('StudentOverview');
const Report = l('Report');
const ReportStudentDetail = l('ReportStudentDetail');
const ReportGroupDetail = l('ReportGroupDetail');
const SchoolManagement = l('SchoolManagement');
const SharedWorldManagement = l('SharedWorldManagement');
const UserManagement = l('UserManagement');
const Work = l('Work');
const WorldManagement = l('WorldManagement');
// This empty function makes sure routes are typed correctly, while also inferring the type of input
// object; e.g. giving us developers autocomplete.
const validateRouteMap = (obj) => obj;
export const routeMap = validateRouteMap({
    '/': {
        name: 'Domeinen',
        component: Domains
    },
    noAccess: {
        name: 'Geen toegang',
        component: NoAccess
    },
    gallery: {
        name: 'Gallery',
        component: Gallery,
        '/': {
            galleryItem(libraryItemId = ':libraryItemId') {
                return {
                    name: '',
                    component: GalleryItemDetails,
                    urlParams: [libraryItemId]
                };
            }
        }
    },
    math: {
        name: 'Details',
        component: Math,
        '/': {
            objectives(domainId = ':domainId', level = ':level') {
                return {
                    name: '',
                    component: Work,
                    urlParams: [domainId, level]
                };
            },
            dashboard: {
                name: 'Math Dashboard',
                component: MathDashboard
            },
            search: {
                name: 'Math Zoeken',
                component: NavSearch
            },
            studentOverview: {
                name: 'Leerlingoverzicht',
                component: StudentOverview
            },
            report: {
                name: 'Rapportage',
                component: Report,
                '/': {
                    group(groupId = ':groupId') {
                        return {
                            name: 'Rapportage',
                            component: Report,
                            urlParams: [groupId]
                        };
                    },
                    studentDetail: {
                        name: 'Student details',
                        component: ReportStudentDetail,
                        '/': {
                            reportStudentDetail(studentId = ':studentId', moduleName = ':moduleName') {
                                return {
                                    name: 'Leerling module Rapport',
                                    component: ReportStudentDetail,
                                    urlParams: [studentId, moduleName]
                                };
                            }
                        }
                    },
                    groupDetail: {
                        name: 'Groep details',
                        component: ReportGroupDetail,
                        '/': {
                            reportGroupDetail(groupId = ':groupId', domainId = ':domainId', level = ':level') {
                                return {
                                    name: 'Groep rapport overzicht',
                                    component: ReportGroupDetail,
                                    urlParams: [groupId, domainId, level]
                                };
                            }
                        }
                    }
                }
            }
        }
    },
    code26: {
        name: 'Details',
        component: Code26,
        '/': {
            dashboard: {
                name: 'Code26 Dashboard',
                component: Code26Dashboard
            },
            search: {
                name: 'Code26 Zoeken',
                component: NavSearch
            },
            studentOverview: {
                name: 'Leerlingoverzicht',
                component: StudentOverview
            },
            report: {
                name: 'Rapportage',
                component: Report,
                '/': {
                    group(groupId = ':groupId') {
                        return {
                            name: 'Rapportage',
                            component: Report,
                            urlParams: [groupId]
                        };
                    },
                    studentDetail: {
                        name: 'Student details',
                        component: ReportStudentDetail,
                        '/': {
                            reportStudentDetail(studentId = ':studentId', moduleName = ':moduleName') {
                                return {
                                    name: 'Leerling module Rapport',
                                    component: ReportStudentDetail,
                                    urlParams: [studentId, moduleName]
                                };
                            }
                        }
                    },
                    groupDetail: {
                        name: 'Groep details',
                        component: ReportGroupDetail,
                        '/': {
                            reportGroupDetail(groupId = ':groupId', domainId = ':domainId', level = ':level') {
                                return {
                                    name: 'Groep rapport overzicht',
                                    component: ReportGroupDetail,
                                    urlParams: [groupId, domainId, level]
                                };
                            }
                        }
                    }
                }
            },
            activities(domainId = ':domainId') {
                return {
                    name: '',
                    component: Activities,
                    urlParams: [domainId]
                };
            },
            objectives(domainId = ':domainId', level = ':level') {
                return {
                    name: '',
                    component: Work,
                    urlParams: [domainId, level]
                };
            }
        }
    },
    expWe: {
        name: 'Details',
        pathname: 'expeditie-wereld',
        component: ExpWe,
        '/': {
            dashboard: {
                name: 'ExpWeDashboard',
                component: ExpWeDashboard
            },
            report: {
                name: 'ExpWe Rapportage',
                component: Report
            },
            worlds: {
                name: '',
                pathname: 'wereld',
                component: null,
                '/': {
                    worldDetails: (worldId = ':worldId') => ({
                        name: '',
                        component: ExpWeWorldDetails,
                        urlParams: [worldId]
                    })
                }
            },
            domains: {
                name: '',
                pathname: 'domein',
                component: null,
                '/': {
                    domain(domainId = ':domainId') {
                        return {
                            name: 'Domein',
                            pathname: 'domein',
                            component: ExpWeDomainDetails,
                            urlParams: [domainId]
                        };
                    }
                }
            },
            work: {
                name: '',
                pathname: 'leerdoelen',
                component: null,
                '/': {
                    world(worldId = ':worldId') {
                        return {
                            name: 'Leerdoelen',
                            pathname: 'leerdoelen',
                            component: ExpWeWork,
                            urlParams: [worldId]
                        };
                    }
                }
            },
            expWeCreateWorld: {
                name: 'Nieuwe Wereld',
                pathname: 'nieuwe-domein-wereld',
                component: ExpWeCreateWorld
            },
            expWeWorldDetails(id = ':id') {
                return {
                    name: 'Wereld Details',
                    pathname: 'wereld-details',
                    component: ExpWeCreateWorld,
                    urlParams: [id]
                };
            }
        }
    },
    exhibitions: {
        name: 'Exhibitions',
        component: Exhibitions,
        '/': {
            new: {
                name: 'Create exhibition',
                component: ExhibitionCreate
            },
            details(exhibitionId = ':exhibitionId') {
                return {
                    name: 'Details',
                    component: ExhibitionDetails,
                    urlParams: [exhibitionId],
                    '/': {
                        update: {
                            name: 'Update exhibition',
                            component: ExhibitionUpdate
                        }
                    }
                };
            }
        }
    },
    backOffice: {
        name: 'Back Office',
        component: BackOffice,
        '/': {
            domains: {
                name: 'Domeinen',
                component: DomainManagement,
                '/': {
                    new: {
                        name: 'Nieuw Domein',
                        component: DataDetails
                    },
                    details(id = ':id') {
                        return {
                            name: 'Details',
                            component: DataDetails,
                            urlParams: [id]
                        };
                    }
                }
            },
            library: {
                name: 'Etalage',
                component: LibraryItemManagement,
                '/': {
                    new: {
                        name: 'Nieuw etalage item',
                        component: DataDetails
                    },
                    details(id = ':id') {
                        return {
                            name: 'Details',
                            component: DataDetails,
                            urlParams: [id]
                        };
                    }
                }
            },
            learningGoals: {
                name: 'Leerdoelen',
                component: LearningGoalManagement,
                '/': {
                    new: {
                        name: 'Nieuw leerdoel',
                        component: DataDetails
                    },
                    details(id = ':id') {
                        return {
                            name: 'Details',
                            component: DataDetails,
                            urlParams: [id]
                        };
                    }
                }
            },
            learningResources: {
                name: 'Leermiddelen',
                component: LearningResourceManagement,
                '/': {
                    new: {
                        name: 'Nieuw leermiddel',
                        component: DataDetails
                    },
                    details(id = ':id') {
                        return {
                            name: 'Details',
                            component: DataDetails,
                            urlParams: [id]
                        };
                    }
                }
            },
            moduleDomains: {
                name: 'Modules',
                component: ModuleDomainManagement,
                '/': {
                    new: {
                        name: 'Nieuwe Module',
                        component: DataDetails
                    },
                    details(id = ':id') {
                        return {
                            name: 'Details',
                            component: DataDetails,
                            urlParams: [id]
                        };
                    }
                }
            },
            schools: {
                name: 'Scholen',
                component: SchoolManagement,
                '/': {
                    new: {
                        name: 'Nieuwe School',
                        component: SchoolDetails
                    },
                    details(schoolId = ':schoolId') {
                        return {
                            name: 'Details',
                            component: SchoolDetails,
                            urlParams: [schoolId],
                            '/': {
                                users: {
                                    name: 'Gebruikers',
                                    component: UserManagement,
                                    '/': {
                                        new: {
                                            name: 'Nieuwe Gebruiker',
                                            component: DataDetails
                                        },
                                        details(id = ':id') {
                                            return {
                                                name: 'Details',
                                                component: DataDetails,
                                                urlParams: [id]
                                            };
                                        }
                                    }
                                },
                                groups: {
                                    name: 'Groepen',
                                    component: GroupManagement,
                                    '/': {
                                        new: {
                                            name: 'Nieuwe Groep',
                                            component: DataDetails
                                        },
                                        details(id = ':id') {
                                            return {
                                                name: 'Details',
                                                component: DataDetails,
                                                urlParams: [id]
                                            };
                                        }
                                    }
                                },
                                learningResources: {
                                    name: 'Leermiddelen',
                                    component: LearningResourceManagement,
                                    '/': {
                                        new: {
                                            name: 'Nieuw Leermiddel',
                                            component: DataDetails
                                        },
                                        details(id = ':id') {
                                            return {
                                                name: 'Details',
                                                component: DataDetails,
                                                urlParams: [id]
                                            };
                                        }
                                    }
                                },
                                totalLicenses: {
                                    name: 'Totaal Licenties',
                                    component: TotalLicenseManagement,
                                    '/': {
                                        new: {
                                            name: 'Nieuwe Licentie',
                                            component: DataDetails
                                        }
                                    }
                                },
                                licenses: {
                                    name: 'Licenties',
                                    component: LicenseManagement,
                                    '/': {
                                        new: {
                                            name: 'Nieuwe Licentie',
                                            component: DataDetails
                                        },
                                        request: {
                                            name: 'Licenties Aanvragen',
                                            component: LicenseRequest
                                        },
                                        details(licenseId = ':licenseId') {
                                            return {
                                                name: 'Details',
                                                component: LicenseDetails,
                                                urlParams: [licenseId]
                                            };
                                        }
                                    }
                                }
                            }
                        };
                    }
                }
            },
            sharedWorlds: {
                name: 'Gedeelde Werelden',
                component: SharedWorldManagement,
                '/': {
                    details: () => ({ name: '', component: null })
                }
            },
            worlds: {
                name: 'Werelden',
                component: WorldManagement,
                '/': {
                    new: {
                        name: 'Nieuwe Wereld',
                        component: DataDetails
                    },
                    details(id = ':id') {
                        return {
                            name: 'Details',
                            component: DataDetails,
                            urlParams: [id]
                        };
                    }
                }
            },
            users: {
                name: 'Gebruikers',
                component: AdminUserManagement,
                '/': {
                    new: {
                        name: 'Nieuwe gebruiker',
                        component: DataDetails
                    },
                    details(id = ':id') {
                        return {
                            name: 'Details',
                            component: DataDetails,
                            urlParams: [id]
                        };
                    }
                }
            },
            news: {
                name: 'Nieuws',
                component: NewsItemManagement,
                '/': {
                    new: {
                        name: 'Nieuw nieuwsbericht',
                        component: DataDetails
                    },
                    details(id = ':id') {
                        return {
                            name: 'Details',
                            component: DataDetails,
                            urlParams: [id]
                        };
                    }
                }
            }
        }
    },
    messages: {
        name: 'Berichten',
        component: Messages,
        pathname: 'berichten'
    },
    news: {
        name: 'Nieuws',
        component: News,
        '/': {
            details(id = ':id') {
                return {
                    name: 'Details',
                    component: NewsItemDetails,
                    urlParams: [id]
                };
            }
        }
    },
    login: {
        name: 'Login',
        component: Login,
        '/': {
            details(message = 'welkom-terug') {
                return { name: 'welkom terug', component: Login, urlParams: [message] };
            }
        }
    },
    forgotPassword: {
        name: 'Wachtwoord Vergeten',
        component: ForgotPassword,
        '/': {
            details(token = ':token') {
                return { name: 'token', component: ForgotPassword, urlParams: [token] };
            }
        }
    },
    '*': {
        name: '404',
        component: NotFound
    }
});
