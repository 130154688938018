var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Globals } from 'utilities/Globals';
import { HttpService } from 'state/HttpService';
import { CustomCrudService } from '../common/custom-crud-service';
export class AuthenticationService extends CustomCrudService {
    constructor() {
        super(Globals.apiUrl + '/authentication', HttpService.instance);
    }
    /**
     * Create an authenticated session
     * @param credentials FormData object with `username` and `password`
     */
    login(credentials) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.post(credentials, {
                populate: ['user.attendingSchool']
            });
        });
    }
    validate() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.http.post([this.controller, 'validate'].join('/'), {}, {
                populate: ['user.attendingSchool']
            });
        });
    }
}
AuthenticationService.instance = new AuthenticationService();
export const authenticationService = AuthenticationService.instance;
